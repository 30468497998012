// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD4Tk5zbSi6ThuoIyN649Ov_H7-xE7oYCg",
    authDomain: "proyecto-4-d644d.firebaseapp.com",
    projectId: "proyecto-4-d644d",
    storageBucket: "proyecto-4-d644d.appspot.com",
    messagingSenderId: "992318493768",
    appId: "1:992318493768:web:b2f44e3d1d4d92d11bf47d",
    measurementId: "G-C6Z7ST1LPE"
};

// npm install firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

export const db = getFirestore(app);