import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ReservationsComponent from '../components/Reservations';
import reservedh from '../../Img/reserved/reservar.jpg';

function Reservations() {
    return (
        <div>
            <Navbar />
            <div style={{ backgroundImage: 'url(' + reservedh + ')', backgroundPosition: 'center', backgroundSize: 'cover', padding: '10%' }}></div>
            <ReservationsComponent />
            <Footer />
        </div>
    );
};

export default Reservations;